import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  applyCreditOptions,
  jobTypeOptions,
  socialSecurityOptions,
  accumulationFundOptions,
  insurancePolicyOptions,
  carProductionOptions,
  realEstateOptions,
  creditSesameOptions,
  creditOptions,
  payoffFormOptions
} from '@/pages/fill-information/options'
import cityDate from '@/assets/js/city'
import activityApi from '@/apis/activity'
import { useSelector } from 'react-redux'
import { RootState } from '.'

// 随机获取options中的其中一个option
const randomOption = (options: {value: string}[], ignoreOptions: number[]|string[] = []) => {
  const filteredOptions = options.filter(option => !ignoreOptions.includes(option.value as never))
  const randomIndex = Math.floor(Math.random() * filteredOptions.length)
  return filteredOptions[randomIndex].value
}

interface AutoForm {
  adChannelCode: string, // 渠道码
  job: string, // 职业
  isHuaWeiPhone: boolean, // 是否华为手机
  applyLimit: string, // 借款金额
  socialSecurity: string, // 社保
  housingFund: string, // 公积金
  insurance: string, // 保险保单
  houseProperty: string, // 房产
  carProperty: string, // 车辆
  sesameCredit: string, // 芝麻分
  realName: string, // 真实姓名
  identity: string, // 身份证号
  workCity: string, // 所在城市
  workCityCode: string, // 所在城市编码
  creditInvestigation: string, // 信用情况
  payoffForm: string, // 工资发放方式
}

interface AutoFormState {
  form: AutoForm,
  isUseful: boolean | null,
  status: 'initial' | 'loading' | 'success' | 'failed'
}

const initialState: AutoFormState = {
  form: {
    adChannelCode: '',
    job: '',
    isHuaWeiPhone: false,
    applyLimit: '',
    socialSecurity: '',
    housingFund: '',
    insurance: '',
    houseProperty: '',
    carProperty: '',
    sesameCredit: '',
    realName: '',
    identity: '',
    workCity: '',
    workCityCode: '',
    creditInvestigation: '',
    payoffForm: '',
  },
  isUseful: null,
  status: 'initial'
}

// 生成表单数据
export const makePartOfAutoForm = createAsyncThunk(
  'autoForm/makePartOfAutoForm',
  async(adChannelCode: string) => {
    const autoForm = {
      adChannelCode,
      isHuaWeiPhone: false,
      applyLimit: randomOption(applyCreditOptions, ['4']),
      job: randomOption(jobTypeOptions),
      socialSecurity: randomOption(socialSecurityOptions),
      housingFund: randomOption(accumulationFundOptions),
      insurance: randomOption(insurancePolicyOptions),
      houseProperty: randomOption(realEstateOptions),
      carProperty: randomOption(carProductionOptions),
      sesameCredit: randomOption(creditSesameOptions),
      identity: '',
      workCity: '',
      workCityCode: '',
      creditInvestigation: randomOption(creditOptions),
      payoffForm: randomOption(payoffFormOptions),
    }

    const res = await activityApi.reverseGeo({ adChannelCode })
    const { province, city } = res.data
    let workCityCode = ''
    cityDate.forEach(item => {
      if (item.label === province) {
        workCityCode = item.value
        item.children.forEach(cityItem => {
          if (cityItem.label === city) {
            workCityCode = `${workCityCode},${cityItem.value}`
          }
        })
      }
    })
    autoForm.workCityCode = workCityCode
    autoForm.workCity = `${province},${city}`
    autoForm.isHuaWeiPhone = navigator.userAgent.toLowerCase().includes('huawei')

    return autoForm
  }
)

// 检查是否可用自动表单
// 注册-表单-下载 流程，且渠道码为海尔消金，且城市为非白名单
export const checkAutoFormUseful = createAsyncThunk(
  'autoForm/checkAutoFormUseful',
  async(adChannelCode: string) => {
    const haierCode = ['r97gwM', 'dhT0rS'] // 海尔消金

    if (!haierCode.includes(adChannelCode)) {
      return false
    }

    try {
      const asyncGetChannelCodeDetail = activityApi
        .getChannelCodeDetail(adChannelCode, {
          isCancelRequest: false,
        })
      const asyncReverseGeo = activityApi.reverseGeo({ adChannelCode })

      const channelCodeDetail = await asyncGetChannelCodeDetail

      // 注册-下载 不启用
      if (Number(channelCodeDetail.linkType) === 1) {
        return false
      }

      const geoRes = await asyncReverseGeo
      const { province, city } = geoRes.data

      const cityCodes = cityDate.find(item => item.label === province)
        ?.children.find(item => item.label === city)?.value

      return !(channelCodeDetail.excludeCityCodes
        && cityCodes
        && channelCodeDetail.excludeCityCodes.includes(cityCodes)
      )
    } catch (error) {
      console.error(error)
      return false
    }
  }
)

export const autoFormSlice = createSlice({
  name: 'autoForm',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      state.form = {
        ...state.form,
        ...action.payload
      }
    },
    resetForm: state => {
      state.form = initialState.form
      state.status = 'initial'
    }
  },
  extraReducers: builder => {
    builder
      .addCase(makePartOfAutoForm.pending, state => {
        state.status = 'loading'
      })
      .addCase(makePartOfAutoForm.fulfilled, (state, action) => {
        state.status = 'success'
        state.form = {
          ...state.form,
          ...action.payload
        }
      })
      .addCase(makePartOfAutoForm.rejected, state => {
        state.status = 'failed'
      })
      .addCase(checkAutoFormUseful.fulfilled, (state, action) => {
        state.isUseful = action.payload
      })
  }
})

export const { updateForm, resetForm } = autoFormSlice.actions
export default autoFormSlice.reducer