/** 登录凭证 */
export const TOKEN = 'TOKEN'

/** 用户信息 */
export const USER_INFO = 'USER_INFO'

/** 顶部推荐产品当前index */
export const TOP_RECOMMEND_INDEX = 'TOP_RECOMMEND_INDEX'

/** 首页弹窗广告当前index */
export const HOME_DIALOG_BANNER_INDEX = 'HOME_DIALOG_BANNER_INDEX'

/** 记录当天首页弹窗广告时间 */
export const HOME_DIALOG_BANNER_TIME = 'HOME_DIALOG_BANNER_TIME'

/** 当前皮肤号 */
export const CURRENT_SKIN_INDEX = 'CURRENT_SKIN_INDEX'

/** 是否进入协议 */
export const ENTER_AGREEMENT = 'ENTER_AGREEMENT'

/** 是否进入协议 */
export const ADCHANNELCODE = 'ad_channel_code'

/**
 * 路由码缓存
 * 数据格式 { [routeCode]: [{ channelCode: ChannelCode, expire: timesnap }] }
**/
export const ROUTE_CODE_MAP = 'ROUTE_CODE_MAP'
