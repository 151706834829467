import { configureStore, ThunkAction, Action, createListenerMiddleware } from '@reduxjs/toolkit'
import userReducer from './user'
import channelReducer from './adChannelCode'
import autoFormReducer from './autoForm'

const listenerMiddleware = createListenerMiddleware()

export const store = configureStore({
  reducer: {
    user: userReducer,
    channel: channelReducer,
    autoForm: autoFormReducer,
  },
  middleware: getDefaultMiddleware => {
    // 添加监听中间件
    return getDefaultMiddleware({
      serializableCheck: {
        // 忽略对应 action，允许传不可序列化的 payload，如回调函数
        ignoredActions: ['inbox/handleWebsocketMessage'],
      },
    }).prepend(listenerMiddleware.middleware)
  }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
