import activityApi from '@/apis/activity'
import { RootState } from '@/store'
import { checkAutoFormUseful } from '@/store/autoForm'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useAutoFormUseful = () => {
  const dispatch = useAppDispatch()
  const isUseful = useAppSelector((state: RootState) => state.autoForm.isUseful)
  const { adChannelCode } = useSelector((state: RootState) => state.channel)
  useEffect(() => {
    if (!adChannelCode) {
      return
    }
    if (isUseful !== null) {
      return
    }
    dispatch(checkAutoFormUseful(adChannelCode))
  }, [isUseful, adChannelCode, dispatch])

  return isUseful
}