import { RootState } from '@/store'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'


/** 项目初始化任务处理 */
import { useEffect } from 'react'
import { FqlPhoneModel } from '@/constants/common'
export const useInitControl = () => {
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()


  // 分期乐手机模型初始化
  const fqlInitGetPhoneModel = () => {
    const phoneModel = search.get('phoneModel')
    let isOppoVivo = phoneModel === FqlPhoneModel.oppo || phoneModel === FqlPhoneModel.vivo
    phoneModel && sessionStorage.setItem('phoneModel', phoneModel)
    if (channelValue.includes('fenqilePhoneModel') && isOppoVivo && phoneModel) {
      sessionStorage.setItem('fql_phoneModel', 'fenqilePhoneModel')
    }
  }

  useEffect(() => {
    fqlInitGetPhoneModel()
  }, [channelValue])
}