import { useEffect, useCallback, useState, useRef } from 'react'
import { useNavigate, Location } from 'react-router-dom'

type BlockingController = {
  showPrompt: boolean
  confirmNavigation: () => void
  cancelNavigation: () => void
}

export const useNavigationBlocker = (isActive: boolean): BlockingController => {
  const navigate = useNavigate()
  const [showPrompt, setShowPrompt] = useState(false)
  const [pendingPath, setPendingPath] = useState<string | null>(null)
  const historyStackRef = useRef<number>(0) // 用于跟踪历史栈层级

  // 初始化历史记录栈
  useEffect(() => {
    if (!isActive) {
      return
    }

    // 标记初始历史层级
    historyStackRef.current = window.history.length

    // 修改当前历史记录条目
    window.history.replaceState(
      {
        ...window.history.state,
        isBlocked: true
      },
      ''
    )
    // 推入一个拦截专用历史条目
    window.history.pushState(
      {
        isBlocked: true,
        isInterceptor: true
      }, // 添加特殊标记
      ''
    )
  }, [isActive])

  // 监听 popstate 事件
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (!isActive) {
        return
      }

      // 检查是否为拦截专用条目
      if (event.state?.isInterceptor) {
        // 立即回退到原始位置
        window.history.go(-1)
        setShowPrompt(true)
        setPendingPath(document.referrer)
        return
      }

      // 正常后退到被拦截条目
      if (event.state?.isBlocked) {
        // 重新添加拦截条目
        window.history.pushState(
          {
            isBlocked: true,
            isInterceptor: true
          },
          ''
        )
        setShowPrompt(true)
        setPendingPath(document.referrer)
      }
    }

    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [isActive])


  // 处理确认导航
  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    if (pendingPath) {
      // 清理拦截状态后跳转
      window.history.replaceState({
        ...window.history.state,
        isBlocked: false
      }, '')
      navigate(pendingPath)
    }
    setPendingPath(null)
  }, [navigate, pendingPath])

  // 处理取消导航
  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
    setPendingPath(null)

    // 恢复初始历史层级
    const currentStack = window.history.length
    if (currentStack > historyStackRef.current) {
      window.history.go(historyStackRef.current - currentStack)
    }
  }, [])

  return {
    showPrompt,
    confirmNavigation,
    cancelNavigation
  }
}