/**
 * 落地页入口参数
 */

// 金信天 RSA 解密手机号
export const JINXINTIAN_RSA_PHONE = 'rp'

// 中飞 H5 解密手机号
export const AES_PHONE = 'phone'

// 金信天 uuid
export const JINXINTIAN_UUID = 'uuid'
